import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Row, Col, Form } from 'reactstrap';
import { venomHistory } from 'client/utils/history/venom-history';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { VEHICLE_PRICE_RANGE } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import {
  buildCurrentUrlWithRandomParam,
  getInventoryTypeParams,
} from 'site-modules/shared/components/search-by-module/search-by-module-utils';
import { DELIVERY_TYPE, DISPLAY_PRICE } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import {
  srpLinkBuilderWithParamsConversion,
  srpLinkBuilder,
} from 'client/site-modules/shared/utils/srp-link-constructor';
import { validation } from 'site-modules/shared/components/validation/validation';
import { validationHelper } from 'site-modules/shared/components/validation/validation-helper/validation-helper';
import { FromToPriceDropdown } from 'site-modules/shared/components/payment-dropdowns/from-to-price-dropdown';
import { SearchByZipAndGo } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-zip-and-go';
import { SearchByFormError } from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-form-error';
import { Select } from 'site-modules/shared/components/inventory/select/select';
import {
  MAKE_MODEL_TAB_RADIOS,
  SearchByBuyOnline,
} from 'site-modules/shared/components/search-by-tabs/search-by-tabs-components/search-by-buy-online';

const VALIDATOR = {
  vehicleType: {
    test: validationHelper.validateFieldLength,
    isRequired: true,
  },
};

export const ERROR_MAP = new Map([['vehicleType', 'Please select a Vehicle Type']]);

export const SEARCH_BY_TYPE_OPTIONS = [
  {
    label: 'Convertible',
    value: 'Convertible',
    filterType: 'bodyType',
  },
  {
    label: 'Coupe',
    value: 'Coupe',
    filterType: 'bodyType',
  },
  {
    label: 'Diesel',
    value: 'diesel',
    filterType: 'engineType',
  },
  {
    label: 'Electric',
    value: 'electric',
    filterType: 'engineType',
  },
  {
    label: 'Full Size Van',
    value: 'Full Size Van',
    flatValue: 'van',
    filterType: 'bodyType',
  },
  {
    label: 'Hatchback',
    value: 'Hatchback',
    filterType: 'bodyType',
  },
  {
    label: 'Hybrid',
    value: 'hybrid',
    filterType: 'engineType',
  },
  {
    label: 'Minivan',
    value: 'Minivan',
    filterType: 'bodyType',
  },
  {
    label: 'Sedan',
    value: 'Sedan',
    filterType: 'bodyType',
  },
  {
    label: 'SUV',
    value: 'SUV',
    filterType: 'bodyType',
  },
  {
    label: 'Truck',
    value: 'Pickup',
    filterType: 'bodyType',
  },
  {
    label: 'Wagon',
    value: 'Station Wagon',
    filterType: 'bodyType',
  },
];

export class SearchByTypeTabUI extends Component {
  static propTypes = {
    onSelectionUpdate: PropTypes.func,
    tracking: PropTypes.shape({}),
    validateForm: PropTypes.func,
    resetValidation: PropTypes.func,
    errorMap: PropTypes.shape({}),
    isUsed: PropTypes.bool,
    isCpo: PropTypes.bool,

    priceRange: PropTypes.string,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    vehicleType: PropTypes.string,
    location: PropTypes.shape({}),
    isSmallContainer: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
  };

  static defaultProps = {
    tracking: {},
    onSelectionUpdate: null,
    validateForm: null,
    resetValidation: null,
    errorMap: new Map(),
    isUsed: false,
    isCpo: false,
    vehicleType: '',
    priceRange: '',
    minPrice: 0,
    maxPrice: 0,
    location: {},
    isSmallContainer: false,
    showRadioButtons: false,
  };

  static getDerivedStateFromProps({ vehicleType, priceRange }, { isValuesRecievedFromProps }) {
    return (vehicleType || priceRange) && !isValuesRecievedFromProps
      ? {
          vehicleType,
          priceRange,
          isValuesRecievedFromProps: !isValuesRecievedFromProps,
        }
      : null;
  }

  state = {
    vehicleType: '',
    priceRange: '',
    isValuesRecievedFromProps: false,
    isZipChangeInProgress: false,
    isSubmitPending: false,
    errorMap: this.props.errorMap,
    buyOnlineSelector: null,
  };

  onZipChangeInProgress = ({ isZipChangeInProgress }) => {
    this.setState({ isZipChangeInProgress }, () => {
      const { isSubmitPending } = this.state;
      if (!isZipChangeInProgress && isSubmitPending) {
        this.validateAndRedirect();
      }
    });
  };

  onSelectPrice = ({ range, minPrice, maxPrice }) =>
    this.setState({ priceRange: range }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('vehicleTypePriceRange', { range, minPrice, maxPrice });
    });

  setVehicleType = ({ value }) => {
    this.props.resetValidation();
    this.setState({ vehicleType: value }, () => {
      const { onSelectionUpdate } = this.props;
      if (onSelectionUpdate) onSelectionUpdate('vehicleType', value);
    });
  };

  validateAndRedirect = () => {
    const { vehicleType } = this.state;

    return this.props
      .validateForm({}, { vehicleType })
      .then(() => !this.state.errorMap.size)
      .then(isValid => {
        if (isValid) {
          this.redirect();
        } else {
          this.setState({ isSubmitPending: false });
        }
      });
  };

  redirect = () => {
    const { isUsed, location, isCpo } = this.props;
    const { vehicleType, priceRange, buyOnlineSelector } = this.state;
    const option = SEARCH_BY_TYPE_OPTIONS.find(({ value }) => value === vehicleType);
    const filterType = get(option, 'filterType');

    let link = srpLinkBuilder({
      ...getInventoryTypeParams(isUsed, isCpo),
      [filterType]: vehicleType,
      [DISPLAY_PRICE]: priceRange,
      ...(buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.IN_STORE && { [DELIVERY_TYPE]: isUsed ? 'local' : null }),
    });

    if (buyOnlineSelector === MAKE_MODEL_TAB_RADIOS.ONLINE) {
      link = srpLinkBuilderWithParamsConversion(
        {
          ...getInventoryTypeParams(isUsed, isCpo),
          [filterType]: vehicleType,
          [DISPLAY_PRICE]: priceRange,
        },
        true
      );
    }

    history.replaceState({}, '', buildCurrentUrlWithRandomParam(location));
    venomHistory.push(link);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isZipChangeInProgress } = this.state;
    this.setState({ isSubmitPending: true });

    if (!isZipChangeInProgress) {
      this.validateAndRedirect();
    }
  };

  toggleBuyOnline = ({ target: { value } }) => {
    this.setState({ buyOnlineSelector: value });
  };

  render() {
    const { vehicleType, errorMap, buyOnlineSelector } = this.state;
    const { tracking, minPrice, maxPrice, isSmallContainer, showRadioButtons } = this.props;
    const error = errorMap.get('vehicleType');

    return (
      <Form autoComplete="off" className="search-by-type-and-price-tab" onSubmit={this.handleSubmit}>
        <Row>
          {showRadioButtons && (
            <Col xs={12} className="mb-0">
              <SearchByBuyOnline
                name="search-by-type-and-price"
                buyOnlineSelector={buyOnlineSelector}
                buyOnlineToggle={this.toggleBuyOnline}
                tracking={tracking}
              />
            </Col>
          )}

          <Col
            xs={12}
            md={isSmallContainer ? 12 : 3}
            lg={3}
            xl={3}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-xl-0': isSmallContainer })}
          >
            <Select
              options={SEARCH_BY_TYPE_OPTIONS}
              value={vehicleType}
              className={classNames('pe-2', { 'with-error': !!error })}
              onChange={this.setVehicleType}
              labelText={null}
              toggle="Select Type"
              labelClassName={classNames({
                'text-danger': !!error,
              })}
              data-tracking-id={tracking.typeSelectTrackingId}
              name="vehicle-type-select"
              valueKey="value"
              labelKey="label"
              size="lg"
            />
            <SearchByFormError error={error} />
          </Col>
          <Col
            xs={12}
            md={isSmallContainer ? 12 : 5}
            lg={isSmallContainer ? 8 : 5}
            xl={5}
            className={classNames('mb-1', { 'mb-md-0': !isSmallContainer, 'mb-xl-0': isSmallContainer })}
          >
            <FromToPriceDropdown
              onSelect={this.onSelectPrice}
              name="vehiclePriceRange"
              minPrice={minPrice}
              maxPrice={maxPrice}
              tracking={{
                minPriceTrackingId: tracking.minPriceSelectTracking(TAB_IDS.TYPE_TAB),
                maxPriceTrackingId: tracking.maxPriceSelectTracking(TAB_IDS.TYPE_TAB),
              }}
              selectProps={{
                options: VEHICLE_PRICE_RANGE,
                labelText: null,
                isFirstOptionDisabled: false,
                disabled: !vehicleType,
                size: 'lg',
                selectComponent: Select,
              }}
            />
          </Col>
          <Col xs={12} md={isSmallContainer ? 12 : 4} lg={4} className="d-flex">
            <SearchByZipAndGo
              onZipChangeInProgress={this.onZipChangeInProgress}
              tracking={tracking}
              facetName={TAB_IDS.TYPE_TAB}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export const SearchByTypeTab = validation(SearchByTypeTabUI, VALIDATOR, ERROR_MAP);
