import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';

import './appraisal-landing-heading-section.scss';

export function AppraisalLandingHeadingSection({
  title,
  subheader,
  breadcrumbs,
  renderAppraisalTabs,
  isAppExtensionPage,
}) {
  useEffect(() => {
    document.body.classList.add('bg-cool-gray-90');

    return () => {
      document.body.classList.remove('bg-cool-gray-90');
    };
  }, []);

  return (
    <div className="appraisal-landing-heading-section pos-r">
      <Container>
        {!isAppExtensionPage && <Breadcrumbs className="ps-0 mb-2 text-white size-12" data={breadcrumbs} />}
        <div className="pos-r">
          <Row>
            <Col xs={12} lg={11}>
              <h1 className="heading-2 mb-0_5 text-white">{title}</h1>
              <ContentFragment classes="page-summary mb-2 text-white">{subheader}</ContentFragment>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={10}>
              {renderAppraisalTabs({
                ctaBtnColor: 'success',
                linksColorClassName: 'text-blue-40',
                accentColorClassName: 'text-blue-40',
                accentBgColorClassName: 'bg-blue-100',
              })}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

AppraisalLandingHeadingSection.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderAppraisalTabs: PropTypes.func.isRequired,
  isAppExtensionPage: PropTypes.bool,
};

AppraisalLandingHeadingSection.defaultProps = {
  isMobile: false,
  isAppExtensionPage: false,
};
