import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SelectComponent } from 'site-modules/shared/components/vehicle-dropdowns/select-component';

export class FromToPriceDropdown extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      isFirstOptionDisabled: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
    wrapperClassNames: PropTypes.string,
    selectMinClassNames: PropTypes.string,
    selectMaxClassNames: PropTypes.string,
    tracking: PropTypes.shape({
      minPriceTrackingId: PropTypes.string,
      maxPriceTrackingId: PropTypes.string,
    }),
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
  };

  static defaultProps = {
    selectProps: {},
    wrapperClassNames: 'd-flex justify-content-between justify-content-md-start align-items-center',
    selectMinClassNames: '',
    selectMaxClassNames: '',
    tracking: {
      minPriceTrackingId: '',
      maxPriceTrackingId: '',
    },
    minPrice: 0,
    maxPrice: 0,
  };

  static getDerivedStateFromProps({ minPrice, maxPrice }, { isValuesRecievedFromProps }) {
    return (minPrice || maxPrice) && !isValuesRecievedFromProps
      ? { minPrice, maxPrice, isValuesRecievedFromProps: !isValuesRecievedFromProps }
      : null;
  }

  state = {
    isValuesRecievedFromProps: false,
    minPrice: 0,
    maxPrice: 0,
  };

  setPriceRange = () => {
    const { minPrice, maxPrice } = this.state;
    const { onSelect } = this.props;
    let range = '';
    if (minPrice || maxPrice) {
      range = `${minPrice || '*'}-${maxPrice || '*'}`;
    }

    onSelect({ range, minPrice, maxPrice });
  };

  handleMinSelect = (selected = {}) => this.setState(() => ({ minPrice: selected.value }), () => this.setPriceRange());

  handleMaxSelect = (selected = {}) => this.setState(() => ({ maxPrice: selected.value }), () => this.setPriceRange());

  render() {
    const {
      selectProps,
      wrapperClassNames,
      selectMinClassNames,
      selectMaxClassNames,
      tracking: { maxPriceTrackingId, minPriceTrackingId },
    } = this.props;
    const { minPrice, maxPrice } = this.state;

    return (
      <div className={wrapperClassNames}>
        <div className="me-1 price-select-wrapper">
          <SelectComponent
            value={minPrice}
            toggle="Min $"
            onChange={this.handleMinSelect}
            name="min-price-select"
            className={classNames('min-price-select', selectMinClassNames)}
            data-tracking-id={minPriceTrackingId}
            {...selectProps}
          />
        </div>
        <div>To</div>
        <div className="ms-1 price-select-wrapper">
          <SelectComponent
            value={maxPrice}
            toggle="Max $"
            onChange={this.handleMaxSelect}
            name="max-price-select"
            className={classNames('max-price-select', selectMaxClassNames)}
            data-tracking-id={maxPriceTrackingId}
            {...selectProps}
          />
        </div>
      </div>
    );
  }
}
